<template>
  <!--begin::Add Edit Booking Modal-->
  <b-modal
      id="modal-booking-add-edit"
      ref="modal"
      centered
      @hidden="resetModal"
  >
    <template #modal-header="{ close }">
      <div class="w-100">
        <h5 class="float-left font-weight-bolder text-dark">{{ isEdit ? $t('BOOKING_CALENDAR.EDIT_DESK_RESERVE_TITLE') : $t('BOOKING_CALENDAR.ADD_DESK_RESERVE_TITLE') }} </h5>
        <span @click="close()" class="float-right cursor-pointer">
            <span class="font-size-h5" aria-hidden="true">&times;</span>
          </span>
      </div>
    </template>
    <form ref="form" @submit.stop.prevent="handleSubmit">
      <b-form-group
          label-for="place-input"
          :invalid-feedback="$t('BOOKING_CALENDAR.DESK_INVALID_FEEDBACK')"
          :state="placeState"
      >
        <template slot="label">{{ $t("BOOKING_CALENDAR.DESK_SELECT_TITLE") }} <span class="font-weight-bolder text-danger">*</span></template>
        <treeselect id="place-input"
                    :state="placeState"
                    v-model="form.resource"
                    :placeholder="$t('SELECT.DEFAULT_TEXT')"
                    :normalizer="normalizer"
                    :clearable="false"
                    :no-results-text="$t('SELECT.NOT_FOUND')"
                    :options="deskList.items"
                    @change="selectedPlace"
                    :disabled="isEdit"
                    required>
          <div slot="value-label" slot-scope="{ node }">{{ node.raw.name }}</div>
        </treeselect>
      </b-form-group>
<!--      <b-form-group-->
<!--          label-for="place-input"-->
<!--          :invalid-feedback="$t('BOOKING_CALENDAR.DESK_INVALID_FEEDBACK')"-->
<!--          :state="placeState"-->
<!--      >-->
<!--        <template slot="label">{{ $t("BOOKING_CALENDAR.DESK_SELECT_TITLE") }} <span class="font-weight-bolder text-danger">*</span></template>-->
<!--        <b-form-select-->
<!--            id="place-input"-->
<!--            v-model="form.resource"-->
<!--            :state="placeState"-->
<!--            @change="selectedPlace"-->
<!--            :disabled="isEdit"-->
<!--            required>-->
<!--          <template #first>-->
<!--            <b-form-select-option :value="null" disabled>{{ $t("SELECT.DEFAULT_TEXT") }}</b-form-select-option>-->
<!--          </template>-->
<!--          <b-form-select-option :value="place.id" v-for="place in deskList.items" :key="place.id">{{ place.name }}</b-form-select-option>-->
<!--        </b-form-select>-->
<!--      </b-form-group>-->
      <b-form-group
          label-for="date-input"
          :invalid-feedback="$t('BOOKING_CALENDAR.DATES_INVALID_FEEDBACK')"
          :state="dateStartState && dateEndState"
      >
        <template slot="label">{{ $t("BOOKING_CALENDAR.DATES_SELECT_TITLE") }} <span class="font-weight-bolder text-danger">*</span></template>
        <div class="row">
          <div class="col-md-5">
            <div class="select-custom input-icon">
              <date-picker class="form-control" name="datePicker" required :state="dateStartState" v-model="form.dateRange.start" :config="options"></date-picker>
              <span><i class="fas fa-calendar-alt icon-custom"></i></span>
            </div>
          </div>
          <div class="col-md-1 pt-3 font-size-lg font-weight-bolder text-primary">&mdash;</div>
          <div class="col-md-5">
            <div class="select-custom input-icon">
              <date-picker class="form-control" name="datePicker" required :state="dateEndState" v-model="form.dateRange.end" :config="options"></date-picker>
              <span><i class="fas fa-calendar-alt icon-custom"></i></span>
            </div>
          </div>
        </div>
      </b-form-group>
      <b-form-group v-if="form.dateRange.start && this.form.dateRange.end" class="mb-2">
        <b-form-checkbox size="lg" v-model="form.recurrentOptions.repeatDateRange" class="booking-calendar-checkbox">
          <span>{{ $t("BOOKING_CALENDAR.REPEAT_CHECKBOX_TEXT") }}</span>
        </b-form-checkbox>
      </b-form-group>
      <b-form-group v-if="form.recurrentOptions.repeatDateRange"
          class="mb-5"
          label-for="repeat-input"
      >
        <div class="row">
          <div class="col-md-5">
            <b-form-select
                id="repeat-input"
                size="sm"
                v-model="form.recurrentOptions.repeatBy">
              <b-form-select-option :value="item.id" v-for="item in repeatByList" :key="item.id">{{ item.name }}</b-form-select-option>
            </b-form-select>
          </div>
        </div>
      </b-form-group>
      <b-form-group v-if="form.recurrentOptions.repeatDateRange && form.recurrentOptions.repeatBy === 'day'">
        <b-form-radio v-model="form.recurrentOptions.eachDay" @change="selectedRepeatRange" name="some-radios" value="some">
          <div class="row">
            <span class="col-3">{{ $t("BOOKING_CALENDAR.EACH_TEXT") }}</span>
            <b-form-input v-model="form.recurrentOptions.eachDayCount" @change="selectedRepeatRange" size="sm" class="col-2" style="margin-top: -5px"></b-form-input>
            <span class="col-2">{{ $t("BOOKING_CALENDAR.DAY_TEXT") }}</span>
          </div>
        </b-form-radio>
        <b-form-radio v-model="form.recurrentOptions.eachDay" @change="selectedRepeatRange" name="some-radios" value="all">{{ $t("BOOKING_CALENDAR.EVERY_WORK_DAY") }}</b-form-radio>
      </b-form-group>
      <b-form-group v-if="form.recurrentOptions.repeatDateRange && form.recurrentOptions.repeatBy === 'week'">
        <div class="row">
          <span class="col-2">{{ $t("BOOKING_CALENDAR.EVERY_TEXT") }}</span>
          <b-form-input v-model="form.recurrentOptions.eachWeekCount" @change="selectedRepeatRange" size="sm" class="col-1" style="margin-top: -5px"></b-form-input>
          <span class="col-3">{{ $t("BOOKING_CALENDAR.WEEK_BY_DAY") }}</span>
        </div>
        <div class="row">
          <div class="col-12 mt-2">
            <b-form-checkbox-group
                @change="selectedRepeatRange"
                v-model="form.recurrentOptions.selectedWeekDays"
                :options="weekDaysOptions"
            ></b-form-checkbox-group>
          </div>
        </div>
      </b-form-group>
      <b-form-group v-if="isCanAgree"
          class="mt-10"
          label-for="user-input"
          :invalid-feedback="$t('BOOKING_CALENDAR.EMPLOYEE_INVALID_FEEDBACK')"
          :state="userState"
      >
        <template slot="label">{{ $t("BOOKING_CALENDAR.EMPLOYEE_SELECT_TITLE") }} <span class="font-weight-bolder text-danger">*</span></template>
        <treeselect id="user-input"
                    :state="userState"
                    v-model="form.recipient"
                    :placeholder="$t('SELECT.DEFAULT_TEXT')"
                    :normalizer="normalizer2"
                    :clearable="false"
                    :no-results-text="$t('SELECT.NOT_FOUND')"
                    :options="employeesList"
                    required>
          <div slot="value-label" slot-scope="{ node }">{{ node.raw.name.display }}</div>
        </treeselect>
      </b-form-group>
<!--      <b-form-group v-if="isCanAgree"-->
<!--                    class="mt-10"-->
<!--                    label-for="user-input"-->
<!--                    :invalid-feedback="$t('BOOKING_CALENDAR.EMPLOYEE_INVALID_FEEDBACK')"-->
<!--                    :state="userState"-->
<!--      >-->
<!--        <template slot="label">{{ $t("BOOKING_CALENDAR.EMPLOYEE_SELECT_TITLE") }} <span class="font-weight-bolder text-danger">*</span></template>-->
<!--        <b-form-select-->
<!--            id="user-input"-->
<!--            v-model="form.recipient"-->
<!--            :state="userState"-->
<!--            required>-->
<!--          <template #first>-->
<!--            <b-form-select-option :value="null" disabled>{{ $t("SELECT.DEFAULT_TEXT") }}</b-form-select-option>-->
<!--          </template>-->
<!--          <b-form-select-option :value="employee.id" v-for="employee in employeesList" :key="employee.id">{{ employee.name.display }}</b-form-select-option>-->
<!--        </b-form-select>-->
<!--      </b-form-group>-->
      <b-form-group v-if="isCanAgree">
        <b-form-checkbox size="lg" v-model="form.approvedStatus" class="booking-calendar-checkbox">
          <span>{{ $t("BOOKING_CALENDAR.AGREED_CHECKBOX_TEXT") }}</span>
        </b-form-checkbox>
        <span class="text-danger ml-7">{{ $t("BOOKING_CALENDAR.AGREED_WARNING_TEXT") }}</span>
      </b-form-group>
    </form>
    <template #modal-footer>
      <div class="w-100">
        <b-button
            variant="light-primary"
            class="float-left font-weight-bolder"
            @click="resetModal">
          {{ $t("BOOKING_CALENDAR.CANCEL_BUTTON") }}
        </b-button>
        <p class="float-right">
          <b-button
              v-if="isEdit"
              variant="danger"
              class="float-left font-weight-bolder mr-2"
              @click="handleDelete">
            {{ $t("BOOKING_CALENDAR.CANCEL_BOOKING_BUTTON") }}
          </b-button>
          <b-button
              class="font-weight-bolder"
              variant="primary"
              @click="handleOk">
            {{ isEdit ? $t("BOOKING_CALENDAR.SAVE_BUTTON") : $t("BOOKING_CALENDAR.ADD_BUTTON") }}
          </b-button>
        </p>
      </div>
    </template>
  </b-modal>
  <!--end::Add Edit Booking Modal-->
</template>

<script>
import moment from 'moment';
import {mapGetters} from "vuex";
import i18n from "../../../core/plugins/vue-i18n";

import {ADD_BODY_CLASSNAME, REMOVE_BODY_CLASSNAME} from "@/core/services/store/htmlclass.module";
import {GET_DESKS_LIST} from "@/core/services/store/management/resources.module";
import {
  CANCEL_BOOKING_CALENDAR_DESK,
  GET_BOOKING_CALENDAR_LIST,
  POST_NEW_BOOKING_CALENDAR_DESK,
  PUT_BOOKING_CALENDAR_DESK
} from "@/core/services/store/bookingCalendar";

export default {
  name: "AddEditBookingModal",
  props: {
    form: {
      type: Object
    },
    options: {
      type: Object
    },
    floorId: {
      type: String
    },
    isEdit: {
      type: Boolean,
      default: false
    },
    filter_form: {
      type: Object
    }
  },
  data() {
    return {
      placeState: null,
      dateStartState: null,
      dateEndState: null,
      userState: null,
      repeatByList: [
        { id: 'day', name: i18n.t("SELECT.REPEAT_BY_DAYS") },
        { id: 'week', name: i18n.t("SELECT.REPEAT_BY_WEEKS") }
      ],
      weekDaysOptions: [
        { text: i18n.t("WEEK_DAYS.MON"), value: 1 },
        { text: i18n.t("WEEK_DAYS.TUE"), value: 2 },
        { text: i18n.t("WEEK_DAYS.WED"), value: 3 },
        { text: i18n.t("WEEK_DAYS.THU"), value: 4 },
        { text: i18n.t("WEEK_DAYS.FRI"), value: 5 },
        { text: i18n.t("WEEK_DAYS.SAT"), value: 6 },
        { text: i18n.t("WEEK_DAYS.SUN"), value: 0 }
      ],
      normalizer(node) {
        return {
          id: node.id,
          label: node.name,
        }
      },
      normalizer2(node) {
        return {
          id: node.id,
          label: node.name.display,
        }
      },
    }
  },
  watch: {
    floorId(val) {
      if (val) this.$store.dispatch(GET_DESKS_LIST, { id: val, params: { page: 1, limit: 500 } });
    }
  },
  computed: {
    ...mapGetters([
      "currentUserAccountInfo",
      "deskList",
      "employeesList"
    ]),
    isCanAgree() {
      if (this.$store.getters.isCan) {
        let role = this.$store.getters.isCan.find(item => item.includes(this.$route.meta.permissions[2]));
        return role !== undefined;
      }
    },
  },
  methods: {
    selectedPlace() {
      // console.log(this.form);
    },
    getDaysBetweenDates(startDate, endDate, format, each) {
      let now = startDate.clone(), dates = [];
      if (format === 'days') {
        while (now.isSameOrBefore(endDate)) {
          dates.push(now.format('DD.MM.YYYY'));
          now.add(each, 'days');
        }
        return dates;
      } else if (format === 'weeks') {
        while (now.isSameOrBefore(endDate)) {
          const date = moment(now, "DD-MM-YYYY");
          if (this.form.recurrentOptions.selectedWeekDays.indexOf(date.day()) >= 0) {
            dates.push(now.format('DD.MM.YYYY'));
          }
          if (date.day() === 0) {
            now.add(each - 1, format);
          }
          now.add(1, 'days');
        }
        return dates;
      }
    },
    selectedRepeatRange() {
      let startDate = moment(this.form.dateRange.start, "DD-MM-YYYY");
      let endDate = moment(this.form.dateRange.end, "DD-MM-YYYY");
      if (this.form.recurrentOptions.repeatBy === 'week') {
        this.form.dates = this.getDaysBetweenDates(startDate, endDate, 'weeks', this.form.recurrentOptions.eachWeekCount);
      } else if (this.form.recurrentOptions.repeatBy === 'day') {
        if (this.form.recurrentOptions.eachDay === 'all' || !this.form.recurrentOptions.repeatDateRange) {
          this.form.dates = this.getDaysBetweenDates(startDate, endDate, 'days', 1);
        } else {
          this.form.dates = this.getDaysBetweenDates(startDate, endDate, 'days', this.form.recurrentOptions.eachDayCount);
        }
      }
    },
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity();
      this.form.resource === null ? this.placeState = false : this.placeState = true;
      this.form.dateRange.start === null ? this.dateStartState = false : this.dateStartState = true;
      this.form.dateRange.end === null ? this.dateEndState = false : this.dateEndState = true;
      this.form.recipient === null ? this.userState = false : this.userState = true;
      return valid;
    },
    checkEmployeesAvatar(res) {
      if (res.items.length > 0 && localStorage.getItem("avatarPhoto")) {
        let avatars = JSON.parse(localStorage.getItem("avatarPhoto"));
        let count = 0;
        let itemsWithPhoto = [];
        res.items.forEach(item => {
          if (item.recipient.id) {
            avatars.items.forEach(avatar => {
              if (item.recipient.id === avatar.id) {
                item.recipient.profile.photo = avatar.photo;
              }
            });
          }
          count++;
          itemsWithPhoto.push(item);
          if (count === res.items.length) {
            res.items = itemsWithPhoto;
            this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
          }
        });
      } else {
        this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
      }
    },
    resetModal() {
      let emptyForm = {
        resource: null,
        dateRange: {
          start: null,
          end: null
        },
        dates: [],
        recipient: null,
        approvedStatus: false,
        recurrentOptions: {
          repeatDateRange: false,
          repeatBy: 'day',
          eachDay: 'all',
          eachDayCount: 2,
          eachWeekCount: 1,
          selectedWeekDays: [1, 3, 5]
        }
      };
      this.$emit('changeForm', emptyForm);
      this.$emit('changeIsEdit', false);
      this.placeState = null;
      this.dateStartState = null;
      this.dateStartEnd = null;
      this.userState = null;
      this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");
      this.$store.dispatch(GET_BOOKING_CALENDAR_LIST, { ...this.filter_form })
        .then(res => {
          this.checkEmployeesAvatar(res);
        });
      this.$bvModal.hide('modal-booking-add-edit');
    },
    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.handleSubmit();
    },
    handleSubmit() {
      if (!this.checkFormValidity()) {
        return;
      }
      if (!this.isCanAgree) {
        this.form.recipient = this.currentUserAccountInfo.id;
      }

      if(!this.form.recurrentOptions.repeatDateRange || this.form.dates.length === 0) {
        this.selectedRepeatRange();
      }

      let form = {
        resource: this.form.resource,
        dates: this.form.dates,
        recipient: this.form.recipient,
        approvedStatus: this.form.approvedStatus,
        recurrentOptions: this.form.recurrentOptions
      }

      if (this.isEdit) {
        this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");
        this.$store.dispatch(PUT_BOOKING_CALENDAR_DESK, { form })
            .then(() => this.$store.dispatch(GET_BOOKING_CALENDAR_LIST, { ...this.filter_form })
                .then(res => {
                  this.checkEmployeesAvatar(res);
                }));
      } else {
        this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");
        this.$store.dispatch(POST_NEW_BOOKING_CALENDAR_DESK, { form })
            .then(() => this.$store.dispatch(GET_BOOKING_CALENDAR_LIST, { ...this.filter_form }).then(res => {
              this.checkEmployeesAvatar(res);
            }));
      }
      this.$nextTick(() => {
        this.$bvModal.hide('modal-booking-add-edit');
      })
    },
    handleDelete() {
      let form = {
        resource: this.form.resource,
        recipient: this.form.recipient
      }
      this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");
      this.$store.dispatch(CANCEL_BOOKING_CALENDAR_DESK, { form })
          .then(() => this.$store.dispatch(GET_BOOKING_CALENDAR_LIST, { ...this.filter_form }).then(res => {
            this.checkEmployeesAvatar(res);
          }));

      this.$nextTick(() => {
        this.$bvModal.hide('modal-booking-add-edit');
      })
    }
  }
}
</script>

<style lang="scss">
  .booking-calendar-checkbox {
    .custom-control-label {
      padding-top: 3px;
    }
  }
</style>
