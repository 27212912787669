<template>
  <!--begin::Booking Calendar-->
  <div v-if="isCan" class="booking-calendar">
    <!--begin::Header-->
    <div class="booking-calendar-header py-4">
      <div class="container-fluid d-flex align-items-center justify-content-between">
        <div class="web-block-picks-search d-flex flex-fill align-items-center mr-5">
          <div class="mr-3">
            <div class="select-custom input-icon">
              <date-picker class="form-control" name="datePicker" @input="setDate" v-model="filter_form.from" :config="options"></date-picker>
              <span><i class="fas fa-calendar-alt icon-custom"></i></span>
            </div>
          </div>
          <div class="flex-fill flex-shrink-1 max-w-400px d-none d-lg-block">
            <MainSelect
                :current-date="filter_form.from"
                @setSelectedOffice="toggleIsOfficeSelected"
                @setFloor="setFloor"
            />
          </div>
        </div>
        <div class="web-block-filter d-none d-md-flex align-items-center justify-content-end w-auto max-w-500px">
          <b-button-group class="mr-3">
            <b-button variant="outline-secondary" :disabled="!isOfficeSelected || counter === 0" @click="changeData('left')">
              <i class="ki ki-bold-arrow-back icon-xs text-dark-50"></i>
            </b-button>
            <b-button variant="outline-secondary" :disabled="true" style="cursor: default">{{ $t("BOOKING_CALENDAR.TODAY_FILTER") }}</b-button>
            <b-button variant="outline-secondary" :disabled="!isOfficeSelected" @click="changeData('right')">
              <i class="ki ki-bold-arrow-next icon-xs text-dark-50"></i>
            </b-button>
          </b-button-group>
          <div class="w-50">
            <treeselect :value="filter_form.format"
                        @select="setFormat"
                        :multiple="false"
                        :clearable="false"
                        :disabled="!isOfficeSelected"
                        :normalizer="normalizer"
                        :options="formatList">
              <div slot="value-label" slot-scope="{ node }">{{ node.raw.name }}</div>
            </treeselect>
          </div>
        </div>
      </div>
      <div class="mobile-block container-fluid d-flex align-items-center justify-content-between mt-2">
        <div class="d-flex flex-fill align-items-center">
          <div class="mr-3">
            <div class="select-custom input-icon">
              <date-picker class="form-control" name="datePicker" @input="setDate" v-model="filter_form.from" :config="options"></date-picker>
              <span><i class="fas fa-calendar-alt icon-custom"></i></span>
            </div>
          </div>
        </div>
        <div class="d-flex flex-fill align-items-center">
          <MainSelect
              :current-date="filter_form.from"
              @setSelectedOffice="toggleIsOfficeSelected"
              @setFloor="setFloor"
          />
        </div>
        <div class="d-md-flex align-items-center justify-content-end w-auto max-w-900px mt-4">
          <b-button-group class="mr-3">
            <b-button variant="outline-secondary" :disabled="!isOfficeSelected || counter === 0" @click="changeData('left')">
              <i class="ki ki-bold-arrow-back icon-xs text-dark-50"></i>
            </b-button>
            <b-button variant="outline-secondary" :disabled="true" style="cursor: default">{{ $t("BOOKING_CALENDAR.TODAY_FILTER") }}</b-button>
            <b-button variant="outline-secondary" :disabled="!isOfficeSelected" @click="changeData('right')">
              <i class="ki ki-bold-arrow-next icon-xs text-dark-50"></i>
            </b-button>
          </b-button-group>
          <div class="w-50">
            <treeselect :value="filter_form.format"
                        @select="setFormat"
                        :multiple="false"
                        :clearable="false"
                        :disabled="!isOfficeSelected"
                        :normalizer="normalizer"
                        :options="formatList">
              <div slot="value-label" slot-scope="{ node }">{{ node.raw.name }}</div>
            </treeselect>
          </div>
        </div>
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div v-if="isOfficeSelected" class="booking-calendar-body card card-custom">
      <div class="card-header card-header-tabs-line flex-wrap">
        <div class="card-toolbar">
          <b-nav tabs class="nav nav-tabs nav-bold nav-tabs-line">
            <b-nav-item to="#desks" :active="$route.hash === '#desks' || $route.hash === ''">
              {{ $t("BOOKING_CALENDAR.DESKS_TAB") }}
            </b-nav-item>
<!--            <b-nav-item to="#meeting-rooms" :active="$route.hash === '#meeting-rooms'">-->
<!--              {{ $t("BOOKING_CALENDAR.MEETING_ROOMS") }}-->
<!--            </b-nav-item>-->
          </b-nav>
        </div>
        <div class="card-title">
          <b-dropdown v-if="isCanExport" right :text="$t('BOOKING_CALENDAR.EXPORT_BUTTON')" style="padding: 0 7px">
            <b-dropdown-item @click="onExportBooking">XLS</b-dropdown-item>
          </b-dropdown>
          <b-button v-if="isCanEdit"
                    v-b-modal.modal-booking-add-edit
                    @click="onAddBooking"
                    class="btn bg-light-primary font-weight-bolder font-size-sm">
            {{ $t("BOOKING_CALENDAR.NEW_BOOKING_BUTTON") }}
          </b-button>
        </div>
      </div>
      <div class="card-body">
        <div class="tab-content">
          <div :class="['tab-pane', { 'active': $route.hash === '#desks' || $route.hash === '' }]" class="p-2">
            <div class="row align-items-center">
              <div class="col-md-2">
                <label class="mb-1 mb-0 d-none d-md-block">{{ $t("TITLE.GROUPS_TITLE") }}</label>
                <treeselect v-model="filter_form.groups"
                            @input="setGroups"
                            :placeholder="$t('TITLE.ALL_FILTER_SELECT')"
                            :multiple="true"
                            :normalizer="normalizer2"
                            :limit="1"
                            :limitText="count => $t('TITLE.AND_SELECT') + ` ${count} ` + $t('TITLE.MORE_SELECT')"
                            :options="groupsList">
                  <div slot="value-label" slot-scope="{ node }">{{ node.raw.name.slice(0, 10) }}</div>
                </treeselect>
              </div>
              <div class="col-md-2">
                <label class="mb-1 mb-0 d-none d-md-block">{{ $t("TITLE.EMPLOYEES_TITLE") }}</label>
                <treeselect v-model="filter_form.users"
                            @input="setUsers"
                            :placeholder="$t('TITLE.ALL_FILTER_SELECT')"
                            :multiple="true"
                            :normalizer="normalizer3"
                            :limit="1"
                            :limitText="count => $t('TITLE.AND_SELECT') + ` ${count} ` + $t('TITLE.MORE_SELECT')"
                            :options="employeesList">
                  <div slot="value-label" slot-scope="{ node }">{{ node.raw.name.display }}</div>
                </treeselect>
              </div>
              <div class="col-md-2">
                <label class="mb-1 mb-0 d-none d-md-block">{{ $t("TITLE.APPROVAL_STATUS_TITLE") }}</label>
                <treeselect v-model="filter_form.approved_status"
                            @input="setApprovedStatus"
                            :placeholder="$t('TITLE.ALL_FILTER_SELECT')"
                            :multiple="false"
                            :clearable="false"
                            :normalizer="normalizer"
                            :options="approvedStatusList">
                  <div slot="value-label" slot-scope="{ node }">{{ node.raw.name }}</div>
                </treeselect>
              </div>
              <div v-if="filter_form.format === 'week'" class="col-md-6 text-right">
                <h5 class="font-weight-bolder text-muted">{{ $t("BOOKING_CALENDAR.WEEK_FILTER_SELECT") }}:</h5>
                <h3 class="font-weight-bolder text-dark">{{ getCurrentDate('week') }}</h3>
              </div>
              <div v-if="filter_form.format === 'month'" class="col-md-6 text-right">
                <h5 class="font-weight-bolder text-muted">{{ $t("BOOKING_CALENDAR.MONTH_FILTER_SELECT") }}:</h5>
                <h3 class="font-weight-bolder text-dark">{{ getCurrentDate('month') }}</h3>
              </div>
            </div>
            <div class="row">
              <div v-if="filter_form.format === 'day'" class="col-md-12">
                <!--begin::Table-->
                <div v-if="deskList.items.length > 0" class="scroll-content table-responsive mt-5">
                  <div class="separator separator-solid my-1"></div>
                  <b-table
                      id="day-calendar-table"
                      class="table-vertical-center"
                      show-empty
                      :items="deskList.items"
                      :fields="dayTimesFields">
                    <template #empty="scope">
                      <h6>{{ $t("TABLE.EMPTY_TITLE") }}</h6>
                    </template>
                    <template #head()="data">
                      <span class="text-muted font-weight-bolder font-weight-lighter border-left-0 border-right-0 px-10">{{ data.label }}</span>
                    </template>
                    <template #cell(name)="data">
                      <h6 class="font-weight-bolder text-dark mb-0">{{ data.item.name }}</h6>
                    </template>
                    <template #cell(1)="data">
                      <div v-for="item in bookingList.items" :key="item.id">
                        <template v-if="isSameDayDate(item, data)">
                          <b-card
                              v-if="isCanEdit && !item.approvedStatus"
                              class="booking-day-card-custom booking-day-card-custom-first border-right-0"
                              :border-variant="item.approvedStatus ? 'success' : 'danger'"
                              header-text-variant="white"
                              :bg-variant="item.approvedStatus ? 'light-success' : 'light-danger'"
                              align="center"
                              v-b-modal.modal-booking-add-edit
                              @click="onEditBooking(item)"
                          >
                            <b-card-text class="font-weight-bolder">
                              <div class="d-flex align-items-center">
                                <div v-if="item.recipient && item.recipient.profile.photo"
                                     class="symbol symbol-circle symbol-25 border border-2 border-danger mr-3">
                                  <img class="symbol-label"
                                       :src="`data:image/png;base64,${item.recipient.profile.photo}`" alt="avatar_img" />
                                </div>
                                <div v-else
                                     class="symbol symbol-circle symbol-25 border border-2 border-danger mr-3">
                                  <img class="symbol-label" :src="picture" alt="avatar_img">
                                </div>
                                {{ item.recipient.name ?
                                  item.recipient.name.last + " " + item.recipient.name.first.charAt(0).toUpperCase() + "." :
                                  item.recipientName.last + " " + item.recipientName.first.charAt(0).toUpperCase() + "." }}
                                <span class="text-muted font-weight-lighter">
                                  {{ item.recipient.profile.job_title ? " ( " + item.recipient.profile.job_title + " )" : null }}
                                </span>
                              </div>
                            </b-card-text>
                          </b-card>
                          <b-card
                              v-else
                              class="booking-day-card-custom booking-day-card-custom-first border-right-0"
                              :border-variant="item.approvedStatus ? 'success' : 'danger'"
                              header-text-variant="white"
                              :bg-variant="item.approvedStatus ? 'light-success' : 'light-danger'"
                              align="center"
                              v-b-tooltip.hover.v-dark="$t('BOOKING_CALENDAR.AGREED_TOOLTIP_TEXT')"
                          >
                            <b-card-text class="font-weight-bolder">
                              <div class="d-flex align-items-center">
                                <div v-if="item.recipient && item.recipient.profile.photo"
                                     class="symbol symbol-circle symbol-25 border border-2 border-success mr-3">
                                  <img class="symbol-label"
                                       :src="`data:image/png;base64,${item.recipient.profile.photo}`" alt="avatar_img" />
                                </div>
                                <div v-else
                                     class="symbol symbol-circle symbol-25 border border-2 border-success mr-3">
                                  <img class="symbol-label" :src="picture" alt="avatar_img">
                                </div>
                                {{ item.recipient.name.last + " " + item.recipient.name.first.charAt(0).toUpperCase() + "." }}
                                <span class="text-muted font-weight-lighter">
                                  {{ item.recipient.profile.job_title ? " ( " + item.recipient.profile.job_title + " )" : null }}
                                </span>
                              </div>
                            </b-card-text>
                          </b-card>
                        </template>
                      </div>
                    </template>
                    <template #cell(10)="data">
                      <div v-for="item in bookingList.items" :key="item.id">
                        <template v-if="isSameDayDate(item, data)">
                          <b-card
                              v-if="isCanEdit && !item.approvedStatus"
                              class="booking-day-card-custom booking-day-card-custom-last border-left-0"
                              :border-variant="item.approvedStatus ? 'success' : 'danger'"
                              header-text-variant="white"
                              :bg-variant="item.approvedStatus ? 'light-success' : 'light-danger'"
                              align="center"
                              v-b-modal.modal-booking-add-edit
                              @click="onEditBooking(item)"
                          >
                            <b-card-text class="font-weight-bolder p-3"></b-card-text>
                          </b-card>
                          <b-card
                              v-else
                              class="booking-day-card-custom booking-day-card-custom-last border-left-0"
                              :border-variant="item.approvedStatus ? 'success' : 'danger'"
                              header-text-variant="white"
                              :bg-variant="item.approvedStatus ? 'light-success' : 'light-danger'"
                              align="center"
                              v-b-tooltip.hover.v-dark="$t('BOOKING_CALENDAR.AGREED_TOOLTIP_TEXT')"
                          >
                            <b-card-text class="font-weight-bolder p-3"></b-card-text>
                          </b-card>
                        </template>
                      </div>
                    </template>
                    <template #cell()="data">
                      <div v-for="item in bookingList.items" :key="item.id">
                        <template v-if="isSameDayDate(item, data)">
                          <b-card
                              v-if="isCanEdit && !item.approvedStatus"
                              class="booking-day-card-custom border-left-0 border-right-0"
                              :border-variant="item.approvedStatus ? 'success' : 'danger'"
                              header-text-variant="white"
                              :bg-variant="item.approvedStatus ? 'light-success' : 'light-danger'"
                              align="center"
                              v-b-modal.modal-booking-add-edit
                              @click="onEditBooking(item)"
                          >
                            <b-card-text class="font-weight-bolder p-3"></b-card-text>
                          </b-card>
                          <b-card
                              v-else
                              class="booking-day-card-custom border-left-0 border-right-0"
                              :border-variant="item.approvedStatus ? 'success' : 'danger'"
                              header-text-variant="white"
                              :bg-variant="item.approvedStatus ? 'light-success' : 'light-danger'"
                              align="center"
                              v-b-tooltip.hover.v-dark="$t('BOOKING_CALENDAR.AGREED_TOOLTIP_TEXT')"
                          >
                            <b-card-text class="font-weight-bolder p-3"></b-card-text>
                          </b-card>
                        </template>
                      </div>
                    </template>
                  </b-table>
                  <div class="separator separator-solid my-1"></div>
                </div>
                <!--end::Table-->
              </div>
              <div v-if="filter_form.format === 'week'" class="col-md-12">
                <!--begin::Table-->
                <div v-if="deskList.items.length > 0" class="scroll-content table-responsive mt-5">
                  <div class="separator separator-solid my-1"></div>
                  <b-table
                      id="week-calendar-table"
                      class="table-vertical-center"
                      show-empty
                      :items="deskList.items"
                      :fields="weekDaysFields">
                    <template #empty="scope">
                      <h6>{{ $t("TABLE.EMPTY_TITLE") }}</h6>
                    </template>
                    <template #head()="data">
                      <span class="text-muted font-weight-bolder font-weight-lighter border-left-0 border-right-0 px-10"
                            :class="data.field.selectedDay ? 'border border-top-dark border-bottom-dark py-4' : ''">{{ data.label }}</span>
                    </template>
                    <template #cell(name)="data">
                      <h6 class="font-weight-bolder text-dark mb-0">{{ data.item.name }}</h6>
                    </template>
                    <template #cell()="data">
                      <div v-if="bookingList && bookingList.items && bookingList.items.length === 0 && isMoreThenYesterday(data)"
                           v-b-modal.modal-booking-add-edit
                           class="name-and-date"
                           @click="selectField(data.field.date)">
                      </div>
                      <div v-if="isMoreThenYesterday(data)"
                           v-b-modal.modal-booking-add-edit
                           class="name-and-date"
                           @click="selectField(data.field.date)">
                      </div>
                      <div v-for="item in bookingList.items" :key="item.id">
                        <template v-if="data.item.id === item.resource.id && isSameWeekDates(item, data)">
                          <b-card
                              v-if="isCanEdit && !item.approvedStatus"
                              class="booking-card-custom"
                              header-text-variant="white"
                              align="center"
                              v-b-modal.modal-booking-add-edit
                              @click="onEditBooking(item)"
                          >
                            <b-card-text class="font-weight-bolder">
                              <div class="d-flex align-items-center">
                                <div v-if="item.recipient && item.recipient.profile.photo"
                                     class="symbol symbol-circle symbol-25 border border-2 border-danger mr-3">
                                  <img class="symbol-label"
                                       :src="`data:image/png;base64,${item.recipient.profile.photo}`" alt="avatar_img" />
                                </div>
                                <div v-else
                                     class="symbol symbol-circle symbol-25 border border-2 border-danger mr-3">
                                  <img class="symbol-label" :src="picture" alt="avatar_img">
                                </div>
                                {{ item.recipient.name ?
                                  item.recipient.name.last + " " + item.recipient.name.first.charAt(0).toUpperCase() + "." :
                                  item.recipientName.last + " " + item.recipientName.first.charAt(0).toUpperCase() + "." }}
                                <span class="text-muted font-weight-lighter" :title="item.recipient.profile.job_title">
                                {{ item.recipient.profile.job_title
                                    ? item.recipient.profile.job_title.length > 15 ? " ( " +  item.recipient.profile.job_title.substring(0, 14) + "..." + " )" : " ( " + item.recipient.profile.job_title + " )"
                                    : null }}
                              </span>
                              </div>
                            </b-card-text>
                          </b-card>
                          <b-card
                              v-else
                              class="booking-card-custom"
                              header-text-variant="white"
                              align="center"
                              v-b-tooltip.hover.v-dark="$t('BOOKING_CALENDAR.AGREED_TOOLTIP_TEXT')"
                          >
                            <b-card-text class="font-weight-bolder">
                              <div class="d-flex align-items-center">
                                <div v-if="item.recipient && item.recipient.profile.photo"
                                     class="symbol symbol-circle symbol-25 border border-2 border-success mr-3">
                                  <img class="symbol-label"
                                       :src="`data:image/png;base64,${item.recipient.profile.photo}`" alt="avatar_img" />
                                </div>
                                <div v-else
                                     class="symbol symbol-circle symbol-25 border border-2 border-success mr-3">
                                  <img class="symbol-label" :src="picture" alt="avatar_img">
                                </div>
                                {{ item.recipient.name.last + " " + item.recipient.name.first.charAt(0).toUpperCase() + "." }}
                                <span class="text-muted font-weight-lighter" :title="item.recipient.profile.job_title">
                                {{ item.recipient.profile.job_title
                                    ? item.recipient.profile.job_title.length > 15 ? " ( " +  item.recipient.profile.job_title.substring(0, 14) + "..." + " )" : " ( " + item.recipient.profile.job_title + " )"
                                    : null }}
                              </span>
                              </div>
                            </b-card-text>
                          </b-card>
                        </template>
                      </div>
                    </template>
                  </b-table>
                  <div class="separator separator-solid my-1"></div>
                </div>
                <!--end::Table-->
              </div>
              <div v-if="filter_form.format === 'month'" class="col-md-12">
                <!--begin::Table-->
                <div v-if="deskList.items.length > 0" class="scroll-content table-responsive mt-5">
                  <div class="separator separator-solid my-1"></div>
                  <b-table
                      id="month-calendar-table"
                      show-empty
                      :items="monthDaysItems"
                      :fields="monthDaysHeaders">
                    <template #empty="scope">
                      <h6>{{ $t("TABLE.EMPTY_TITLE") }}</h6>
                    </template>
                    <template #head()="data">
                      <span class="text-muted font-weight-bolder font-weight-lighter px-10">{{ data.label }}</span>
                    </template>
                    <template #cell()="data">
                      <div v-for="field in monthDaysFields()" :key="field.key" style="position: relative">
                        <template v-if="data.field.label.includes(field.label.substring(0, 2))">
                          <div class="mb-10">
                            <p class="font-weight-bolder">{{ field.label }}</p>
                            <div class="custom-items-cell">
                              <div v-for="item in bookingList.items" :key="item.id">
                                <template v-if="isSameMonthDates(item, field)">
                                  <b-card
                                      v-if="isCanEdit && !item.approvedStatus"
                                      class="booking-card-custom"
                                      header-text-variant="white"
                                      align="center"
                                      v-b-modal.modal-booking-add-edit
                                      @click="onEditBooking(item)"
                                  >
                                    <b-card-text class="font-weight-bolder">
                                      <div class="d-flex align-items-center">
                                        <div v-if="item.recipient && item.recipient.profile.photo"
                                             class="symbol symbol-circle symbol-25 border border-2 border-danger mr-3">
                                          <img class="symbol-label"
                                               :src="`data:image/png;base64,${item.recipient.profile.photo}`" alt="avatar_img" />
                                        </div>
                                        <div v-else
                                             class="symbol symbol-circle symbol-25 border border-2 border-danger mr-3">
                                          <img class="symbol-label" :src="picture" alt="avatar_img">
                                        </div>
                                        {{ item.recipient.name ?
                                          item.recipient.name.last + " " + item.recipient.name.first.charAt(0).toUpperCase() + "." :
                                          item.recipientName.last + " " + item.recipientName.first.charAt(0).toUpperCase() + "." }}
                                        <span class="text-muted font-weight-lighter" :title="item.recipient.profile.job_title">
                                          {{ item.recipient.profile.job_title
                                                  ? item.recipient.profile.job_title.length > 15 ? " ( " +  item.recipient.profile.job_title.substring(0, 14) + "..." + " )" : " ( " + item.recipient.profile.job_title + " )"
                                                  : null }}
                                        </span>
                                      </div>
                                    </b-card-text>
                                  </b-card>
                                  <b-card
                                      v-else
                                      class="booking-card-custom"
                                      header-text-variant="white"
                                      align="center"
                                      v-b-tooltip.hover.v-dark="$t('BOOKING_CALENDAR.AGREED_TOOLTIP_TEXT')"
                                  >
                                    <b-card-text class="font-weight-bolder">
                                      <div class="d-flex align-items-center">
                                        <div v-if="item.recipient && item.recipient.profile.photo"
                                             class="symbol symbol-circle symbol-25 border border-2 border-success mr-3">
                                          <img class="symbol-label"
                                               :src="`data:image/png;base64,${item.recipient.profile.photo}`" alt="avatar_img" />
                                        </div>
                                        <div v-else
                                             class="symbol symbol-circle symbol-25 border border-2 border-success mr-3">
                                          <img class="symbol-label" :src="picture" alt="avatar_img">
                                        </div>
                                        {{ item.recipient.name.last + " " + item.recipient.name.first.charAt(0).toUpperCase() + "." }}
                                        <span class="text-muted font-weight-lighter" :title="item.recipient.profile.job_title">
                                          {{ item.recipient.profile.job_title
                                                  ? item.recipient.profile.job_title.length > 15 ? " ( " +  item.recipient.profile.job_title.substring(0, 14) + "..." + " )" : " ( " + item.recipient.profile.job_title + " )"
                                                  : null }}
                                        </span>
                                      </div>
                                    </b-card-text>
                                  </b-card>
                                </template>
                              </div>
                            </div>
                            <b-link v-if="field.counter > 4" @click="onShowMore(field)" style="position: absolute">{{ $t("BOOKING_CALENDAR.SHOW_ALL_LINK_TEXT") }}</b-link>
                          </div>
                        </template>
                      </div>
                    </template>
                  </b-table>
                  <div class="separator separator-solid my-1"></div>
                </div>
                <!--end::Table-->
              </div>
            </div>
          </div>
<!--          <div :class="['tab-pane', { 'active': $route.hash === '#meeting-rooms' }]" class="p-2"></div>-->
        </div>
      </div>
    </div>
    <!--end::Body-->
    <!--begin::Add Edit Booking Modal-->
    <AddEditBookingModal
        :form="form"
        :options="options"
        :floorId="changeFloorId"
        :isEdit="isEdit"
        :filter_form="filter_form"
        @changeIsEdit="toggleIsEdit"
        @changeForm="clearForm"/>
    <!--end::Add Edit Booking Modal-->
  </div>
  <!--end::Booking Calendar-->
</template>

<script>
import moment from 'moment';
import {mapGetters} from "vuex";
import i18nService from "@/core/services/i18n.service.js";
import i18n from "../../../core/plugins/vue-i18n";

import {GET_GROUPS_LIST} from "@/core/services/store/management.module";
import {GET_EMPLOYEES_LIST} from "@/core/services/store/management.module";
import {GET_BOOKING_CALENDAR_LIST} from "@/core/services/store/bookingCalendar";
import {EXPORT_BOOKING_CALENDAR_DESK} from "@/core/services/store/bookingCalendar";
import {ADD_BODY_CLASSNAME, REMOVE_BODY_CLASSNAME} from "@/core/services/store/htmlclass.module";

import MainSelect from "@/view/layout/extras/MainSelect";
import AddEditBookingModal from "@/view/layout/modal/AddEditBookingModal";
import {sendAmplitudeEvent} from "@/core/services/amplitude.service";

export default {
  name: "Booking-calendar",
  components: {
    MainSelect,
    AddEditBookingModal
  },
  mounted() {
    this.$store.dispatch(GET_GROUPS_LIST, { isVisibleInReports: 1 });
    this.$store.dispatch(GET_EMPLOYEES_LIST, { limit: 500 });
  },
  created() {
    this.setAmplitudeEventType('booking-calendar show');
  },
  data() {
    return {
      isOfficeSelected: false,
      floor: null,
      isEdit: false,
      counter: 0,
      selectedDay: null,
      filter_form: {
        format: 'week',
        from: new Date(),
        to: new Date(),
        floor: null,
        approved_status: null,
        groups: null,
        users: null
      },
      form: {
        resource: null,
        dateRange: {
          start: null,
          end: null
        },
        dates: [],
        recipient: null,
        approvedStatus: false,
        recurrentOptions: {
          repeatDateRange: false,
          repeatBy: 'day',
          eachDay: 'all',
          eachDayCount: 2,
          eachWeekCount: 1,
          selectedWeekDays: [1, 3, 5]
        }
      },
      options: {
        locale: i18nService.getActiveLanguage(),
        format: 'DD.MM.YYYY',
        useCurrent: true,
        // defaultDate: new Date(),
        minDate: new Date(new Date().setDate(new Date().getDate() - 1)),
        tooltips: {
          today: 'Go to today',
          clear: 'Clear selection',
          close: 'Close the picker',
          selectMonth: 'Select Month',
          prevMonth: 'Previous Month',
          nextMonth: 'Next Month',
          selectYear: 'Select Year',
          prevYear: 'Previous Year',
          nextYear: 'Next Year',
          selectDecade: 'Select Decade',
          prevDecade: 'Previous Decade',
          nextDecade: 'Next Decade',
          prevCentury: 'Previous Century',
          nextCentury: 'Next Century'
        }
      },
      normalizer(node) {
        return {
          id: node.value,
          label: node.name,
        }
      },
      normalizer2(node) {
        return {
          id: node.id,
          label: node.name,
        }
      },
      normalizer3(node) {
        return {
          id: node.id,
          label: node.name.display,
        }
      },
      formatList: [
        { name: i18n.t("BOOKING_CALENDAR.DAY_FILTER_SELECT"), value: "day"},
        { name: i18n.t("BOOKING_CALENDAR.WEEK_FILTER_SELECT"), value: "week" },
        { name: i18n.t("BOOKING_CALENDAR.MONTH_FILTER_SELECT"), value: "month" }
      ],
      approvedStatusList: [
        { name: i18n.t("TITLE.ALL_FILTER_SELECT"), value: 'null' },
        { name: i18n.t("BOOKING_CALENDAR.YES_FILTER_SELECT"), value: true },
        { name: i18n.t("BOOKING_CALENDAR.NO_FILTER_SELECT"), value: false }
      ]
    }
  },
  computed: {
    ...mapGetters([
      "groupsList",
      "employeesList",
      "deskList",
      "bookingList"
    ]),
    isCan() {
      if (this.$store.getters.isCan) {
        let role = this.$store.getters.isCan.find(item => item.includes(this.$route.meta.permissions[0]))
        if (role === undefined) {
          this.$router.push({ name: 'my-office' })
          return false;
        } else {
          return true;
        }
      }
    },
    isCanEdit() {
      if (this.$store.getters.isCan) {
        let role = this.$store.getters.isCan.find(item => item.includes(this.$route.meta.permissions[1]));
        return role !== undefined;
      }
    },
    isCanExport() {
      if (this.$store.getters.isCan) {
        let role = this.$store.getters.isCan.find(item => item.includes(this.$route.meta.permissions[3]));
        return role !== undefined;
      }
    },
    picture() {
      return process.env.BASE_URL + "media/default-org-logo.png";
    },
    changeFloorId() {
      return this.filter_form.floor;
    },
    dayTimesFields() {
      return [
        { key: 'name', label: '' },
        { key: '1', label: '09:00' },
        { key: '2', label: '10:00' },
        { key: '3', label: '11:00' },
        { key: '4', label: '12:00' },
        { key: '5', label: '13:00' },
        { key: '6', label: '14:00' },
        { key: '7', label: '15:00' },
        { key: '8', label: '16:00' },
        { key: '9', label: '17:00' },
        { key: '10', label: '18:00' }
      ]
    },
    weekDaysFields() {
      let dates = [ { key: 'name', label: '' } ];
      let key = 2;
      let firstDate = moment(this.filter_form.from, 'DD.MM.YYYY').startOf('week');
      let lastDate = moment(this.filter_form.to, 'DD.MM.YYYY').startOf('day');
      if (moment(firstDate, 'dd/mm/yyyy').isSame(moment(this.selectedDay, 'DD.MM.YYYY'), 'day')) {
        dates.push({ key: '1', label: moment(firstDate).locale(i18nService.getActiveLanguage()).format('ddd, D MMM'), date: moment(firstDate).format('DD.MM.YYYY'), selectedDay: true });
      } else {
        dates.push({ key: '1', label: moment(firstDate).locale(i18nService.getActiveLanguage()).format('ddd, D MMM'), date: moment(firstDate).format('DD.MM.YYYY') });
      }
      while(firstDate.add(1, 'days').diff(lastDate) <= 0) {
        if (moment(firstDate, 'dd/mm/yyyy').isSame(moment(this.selectedDay, 'DD.MM.YYYY'), 'day')) {
          dates.push({ key: key.toString(), label: moment(firstDate).locale(i18nService.getActiveLanguage()).format('ddd, D MMM'), date: moment(firstDate).format('DD.MM.YYYY'), selectedDay: true });
        } else {
          dates.push({ key: key.toString(), label: moment(firstDate).locale(i18nService.getActiveLanguage()).format('ddd, D MMM'), date: moment(firstDate).format('DD.MM.YYYY') });
        }
        key++
      }
      return dates;
    },
    monthDaysHeaders() {
      return [
        { key: '1', label: moment(this.filter_form.from, 'DD.MM.YYYY').day(1).locale(i18nService.getActiveLanguage()).format('ddd') },
        { key: '2', label: moment(this.filter_form.from, 'DD.MM.YYYY').day(2).locale(i18nService.getActiveLanguage()).format('ddd') },
        { key: '3', label: moment(this.filter_form.from, 'DD.MM.YYYY').day(3).locale(i18nService.getActiveLanguage()).format('ddd') },
        { key: '4', label: moment(this.filter_form.from, 'DD.MM.YYYY').day(4).locale(i18nService.getActiveLanguage()).format('ddd') },
        { key: '5', label: moment(this.filter_form.from, 'DD.MM.YYYY').day(5).locale(i18nService.getActiveLanguage()).format('ddd') },
        { key: '6', label: moment(this.filter_form.from, 'DD.MM.YYYY').day(6).locale(i18nService.getActiveLanguage()).format('ddd') },
        { key: '7', label: moment(this.filter_form.from, 'DD.MM.YYYY').day(0).locale(i18nService.getActiveLanguage()).format('ddd') }
      ]
    },
    monthDaysItems() {
      return [
        { key: '1', label: '' }
      ]
    }
  },
  methods: {
    selectField(date) {
      this.isEdit = false;
      this.form.dateRange.start = date;
      this.form.dateRange.end = date;
    },
    setAmplitudeEventType(eventType) {
      sendAmplitudeEvent(eventType);
    },
    toggleIsOfficeSelected(bool) {
      this.isOfficeSelected = bool;
    },
    isSameDayDate(item, data) {
      if (item.resource.id === data.item.id) {
        return true;
      }
      return false;
    },
    isMoreThenYesterday(data) {
      let label = moment(data.field.date, 'DD.MM.YYYY');
      let yesterday = moment(new Date(), "DD-MM-YYYY").subtract(1, 'day');
      return label.isSameOrAfter(yesterday);
    },
    isSameWeekDates(item, data) {
      let label = moment(data.field.date, 'DD.MM.YYYY').startOf('day');
      let start = moment(item.dateRange.start, 'YYYY-MM-DD').startOf('day');
      if (label.format('YYYY-MM-DD') === start.format('YYYY-MM-DD')) {
        return true;
      }
      return false;
    },
    isSameMonthDates(item, field) {
      let label = moment(field.date, 'DD.MM.YYYY').startOf('day')
      let start = moment(item.dateRange.start, 'YYYY-MM-DD').startOf('day');
      if (label.format('YYYY-MM-DD') === start.format('YYYY-MM-DD')) {
        field.counter++;
        return true;
      }
      return false;
    },
    getCurrentDate(format) {
      if (format === 'week') {
        let from = moment(this.filter_form.from, 'DD.MM.YYYY').startOf('week').locale(i18nService.getActiveLanguage());
        let to = moment(this.filter_form.to, 'DD.MM.YYYY').locale(i18nService.getActiveLanguage());
        return from.format('ddd, D MMM') + " - " + to.format('ddd, D MMM');
      } else if (format === 'month') {
        let from = moment(this.filter_form.from, 'DD.MM.YYYY').locale(i18nService.getActiveLanguage());
        return from.format('MMMM').charAt(0).toUpperCase() + from.format('MMMM').slice(1);
      }
    },
    toggleIsEdit(variable) {
      this.isEdit = variable;
    },
    clearForm(variable) {
      this.form = variable;
    },
    onExportBooking() {
      this.$store.dispatch(EXPORT_BOOKING_CALENDAR_DESK, { form: this.filter_form });
    },
    onAddBooking() {
      this.isEdit = false;
    },
    onEditBooking(item) {
      this.isEdit = true;
      // this.form = item;
      this.form.resource = item.resource.id;
      this.form.dateRange.start = moment(item.dateRange.start).format('DD.MM.YYYY');
      this.form.dateRange.end = moment(item.dateRange.end).format('DD.MM.YYYY');
      this.form.recurrentOptions = item.recurrentOptions ? JSON.parse(item.recurrentOptions) : this.form.recurrentOptions;
      this.form.recipientName = item.recipient.name;
      this.form.recipient = item.recipient.id;
      this.form.approvedStatus = item.approvedStatus;
    },
    setFilter() {
      this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");
      let startOfWeek = moment(this.filter_form.from, "DD-MM-YYYY");
      let today = moment(new Date(), "DD-MM-YYYY");
      if (this.filter_form.format === 'day') {
        this.filter_form.from = moment(this.filter_form.from, 'DD.MM.YYYY').startOf(this.filter_form.format).format('DD.MM.YYYY');
        this.filter_form.to = moment(this.filter_form.from, 'DD.MM.YYYY').endOf(this.filter_form.format).format('DD.MM.YYYY');
      } else {
        if (startOfWeek.diff(today, 'days') <= 0) {
          this.filter_form.from = moment(today).format('DD.MM.YYYY');
          this.filter_form.to = moment(today, 'DD.MM.YYYY').endOf(this.filter_form.format).format('DD.MM.YYYY');
        } else {
          this.filter_form.from = moment(this.filter_form.from, 'DD.MM.YYYY').startOf(this.filter_form.format).format('DD.MM.YYYY');
          this.filter_form.to = moment(this.filter_form.from, 'DD.MM.YYYY').endOf(this.filter_form.format).format('DD.MM.YYYY');
        }
      }
      this.filter_form.approved_status === 'null' ? this.filter_form.approved_status = null : this.filter_form.approved_status;
      this.$store.dispatch(GET_BOOKING_CALENDAR_LIST, { ...this.filter_form })
        .then(res => {
          if (localStorage.getItem("avatarPhoto")) {
            let avatars = JSON.parse(localStorage.getItem("avatarPhoto"));
            let count = 0;
            let itemsWithPhoto = [];
            if (res.items.length > 0) {
              res.items.forEach(item => {
                if (item.recipient.id) {
                  avatars.items.forEach(avatar => {
                    if (item.recipient.id === avatar.id) {
                      item.recipient.profile.photo = avatar.photo;
                    }
                  });
                }
                count++;
                itemsWithPhoto.push(item);
                if (count === res.items.length) {
                  res.items = itemsWithPhoto;
                  this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
                }
              });
            } else {
              this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
            }
          }
        });
    },
    setDate(val) {
      this.selectedDay = val;
      this.setFilter();
      // console.log('Last Week start: ' + moment().subtract(1, 'weeks').startOf('week').format('DD.MM.YYYY'));
      // console.log('Last Week end: ' + moment().subtract(1, 'weeks').endOf('week').format('DD.MM.YYYY'));
      // console.log('Start Week:' + moment(val, 'DD.MM.YYYY').startOf('week').format('DD.MM.YYYY'));
      // console.log('End Week:' + moment(val, 'DD.MM.YYYY').endOf('week').format('DD.MM.YYYY'));
      // console.log('Next Week start: ' + moment().subtract(-1, 'weeks').startOf('week').format('DD.MM.YYYY'));
      // console.log('Next Week end: ' + moment().subtract(-1, 'weeks').endOf('week').format('DD.MM.YYYY'));
      // console.log('Last Month: ' + moment().subtract(1, 'months').startOf('month').format('DD.MM.YYYY'));
      // console.log('Start Month: ' + moment(val, 'DD.MM.YYYY').startOf('month').format('DD.MM.YYYY'));
      // console.log('End Month: ' + moment(val, 'DD.MM.YYYY').endOf('month').format('DD.MM.YYYY'));
      // console.log('Next Month: ' + moment().subtract(-1, 'months').startOf('month').format('DD.MM.YYYY'));
    },
    changeData(changeTo) {
      changeTo === 'left' && this.counter < 0 ? this.counter++ : this.counter--;
      // moment().subtract(this.counter, 'weeks').startOf('week').format('DD.MM.YYYY');
      this.filter_form.from = moment().subtract(this.counter, this.filter_form.format).format('DD.MM.YYYY');
      this.setFilter();
    },
    setFloor(val) {
      this.filter_form.floor = val;
      this.setFilter();
    },
    setFormat(val) {
      // if (this.filter_form.format !== val) {
        this.filter_form.format = val.value;
        this.counter = 0;
        this.setFilter();
      // }
    },
    setGroups(val) {
      this.filter_form.groups.length === 0 ? this.filter_form.groups = null : this.filter_form.groups = val;
      if (val.length > 0) {
        let groups = val.join(',');
        this.$store.dispatch(GET_EMPLOYEES_LIST,{ limit: 500, groups });
      } else {
        this.$store.dispatch(GET_EMPLOYEES_LIST,{ limit: 500 });
      }
      this.setFilter();
    },
    setUsers(val) {
      this.filter_form.users.length === 0 ? this.filter_form.users = null : this.filter_form.users = val;
      this.setFilter();
    },
    setApprovedStatus(val) {
      this.filter_form.approved_status = val;
      this.setFilter();
    },
    onShowMore(field) {
      this.filter_form.from = field.date;
      this.filter_form.format = 'day';
      this.setFilter();
    },
    monthDaysFields() {
      let dates = [];
      let key = 2;
      // IF STARTING FROM 1-st EVERY MONTH
      // let firstDate = moment(this.filter_form.from, 'DD.MM.YYYY').startOf('month');
      // dates.push({ key: '1', label: moment(firstDate).format('ddd, D MMMM'), date: firstDate.format('DD.MM.YYYY') });
      // while(firstDate.add(1, 'days').diff(lastDate) <= 0) {
      //   dates.push({ key: key.toString(), label: moment(firstDate).format('ddd, D MMMM'), date: firstDate.format('DD.MM.YYYY') });
      //   key++
      // }
      let lastDate = moment(this.filter_form.to, 'DD.MM.YYYY').startOf('day');
      let startOfWeekFirstDate = moment(this.filter_form.from, 'DD.MM.YYYY').startOf('month').startOf('week');
      dates.push({
        key: '1',
        counter: 0,
        label: moment(startOfWeekFirstDate).locale(i18nService.getActiveLanguage()).format('ddd, D MMMM'),
        date: startOfWeekFirstDate.format('DD.MM.YYYY')
      });
      while(startOfWeekFirstDate.add(1, 'days').diff(lastDate) <= 0) {
        dates.push({
          key: key.toString(),
          counter: 0,
          label: moment(startOfWeekFirstDate).locale(i18nService.getActiveLanguage()).format('ddd, D MMMM'),
          date: startOfWeekFirstDate.format('DD.MM.YYYY')
        });
        key++
      }
      return dates
    },
  }
}
</script>

<style lang="scss">
  //.booking-calendar-body {
  //  height: 100vh;
  //  overflow: hidden;
  //}
  .scroll-content {
    display: block;
    height: 85vh;
    overflow: scroll;
    #day-calendar-table,
    #week-calendar-table,
    #month-calendar-table {
      thead {
        width: max-content;
        z-index: 10;
        position: sticky;
        top: 0;
        background: white;
      }
      tbody {
        td{
          position: relative;
          .name-and-date {
            position: absolute;
            top: 20%;
            left: 10%;
            width: 80%;
            height: 60%;
            &:hover {
              background-color: #F64E60;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
  .booking-calendar {
    margin: -25px;
    &-header {
      z-index: 10;
      width: 100%;
      background-color: #FFFFFF;
    }
    &-body {
      margin: 25px 25px;
    }
    .custom-items-cell {
      height: 145px!important;
      overflow: hidden!important;
    }
  }
  .booking-card-custom {
    padding: 0 0!important;
    margin: 1px 1px!important;
    //width: max-content;
    .card-body {
      padding: 2px 2px!important;
    }
  }
  .booking-day-card-custom {
    padding: 4.5px 0 5px;
    margin: 2px -10px!important;
    border: none;
    border-radius: 0;
    .card-body {
      padding: 2px 5px;
    }
  }
  .booking-day-card-custom-first {
    padding: 0 0!important;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }
  .booking-day-card-custom-last {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  @media screen and (min-width: 991.98px) {
    .mobile-block {
      display: none !important;
    }
  }

  @media screen and (max-width: 991.97px) {
    .my-office-header {
      padding-bottom: 4rem !important;
    }
    .points-control-block {
      bottom: 90px;
    }
    .web-block-picks-search,
    .web-block-filter {
      display: none !important;
    }
    .mobile-block {
      min-width: 100% !important;
      display: block !important;
      position: relative;
      z-index: 1 !important;
    }
  }
</style>
